import { Avatar, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import CheckIcon from "@material-ui/icons/Check"
import WarningIcon from "@material-ui/icons/Warning"
import LoadingBackdrop from "./LoadingBackDrop"
import { navigate } from "gatsby"
import { restUnauth } from "../services/rest"

const errorMessages = {
  "confirmation time exceeded": `Désolé, le délai de confirmation a été dépassé.\n\nVous allez être redirigé vers la page de votre commerçant`,
  "booking already confirmed":
    "Le rendez-vous a déjà été confirmé\n\nVous allez être redirigé vers la page du rendez-vous",
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    background: theme.palette.primary.main,
    alignItems: "center",
    justifyContent: "space-around",
    padding: 20,
  },
  icon: { ...theme.typography.h1, color: "green" },
}))

export default function BookConfirm({ id }) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [booking, setBooking] = React.useState()
  React.useEffect(() => {
    if (booking && booking.error) {
      setTimeout(() => {
        if (booking.error === "confirmation time exceeded") {
          navigate(`/${booking.projectSlug}`)
        }
        if (booking.error === "booking already confirmed") {
          navigate(`/booking/${id}`)
        }
      }, 5000)
    }
  }, [booking])
  React.useEffect(() => {
    const fetchBooking = async () => {
      if (!id) {
        console.warn("no id")
        setError("Désolé, cette page n'existe pas, veuillez vérifier l'adresse")
        return
      }
      setError(false)
      setLoading(true)
      return restUnauth("post", "/open/confirmBooking", {
        id,
      })
    }
    fetchBooking()
      .then(data => {
        console.log({ data })
        setBooking(data)
        if (data.error) setError(data.error)
      })
      .catch(err => {
        console.warn(err)
        setError(err)
      })
      .finally(() => setLoading(false))
  }, [id])
  return (
    <div className={classes.root}>
      <LoadingBackdrop open={loading} />
      {!loading && (
        <div className={classes.root}>
          <Avatar
            style={{ background: "white", width: "20vw", height: "20vw" }}
          >
            {error ? (
              <WarningIcon className={classes.icon} style={{ color: "red" }} />
            ) : (
              <CheckIcon className={classes.icon} />
            )}
          </Avatar>
          <Typography
            variant="h4"
            style={{
              color: "white",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            {error
              ? errorMessages[error] || error.message || error
              : "Votre rendez-vous est bien réservé !"}
          </Typography>
          {!error && (
            <Typography
              variant="h6"
              style={{ color: "white", textAlign: "center" }}
            >
              Consultez votre boîte de réception pour retrouver les détails de
              votre rendez-vous et ajouter cet évènement à votre agenda.
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}
