/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import AppBar from "./AppBar"
import { makeStyles, Toolbar } from "@material-ui/core"
import Footer from "./Footer"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh",
    // overflow: "hidden",
    flexDirection: "column",
    // border: "1px solid blue",
  },
  columnContainer: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
    flexDirection: "column",
    margin: 0,
    padding: 0,
    // border: "1px solid red",
  },
}))

const Layout = ({ children, location, showFooter = true }) => {
  const classes = useStyles()
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  console.log({ location })
  return (
    <div className={classes.root}>
      <AppBar location={location} noMenu />
      <div className={classes.columnContainer}>
        <main className={classes.columnContainer}>
          <Toolbar />
          {children}
        </main>
      </div>
      {showFooter ? <Footer /> : null}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
