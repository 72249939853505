import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import BookConfirm from "../components/BookConfirm"

export default function Confirm() {
  return (
    <Layout>
      <Router style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <BookConfirm default path="/bookconfirm/:id" />
        <BookConfirm path="/bookconfirm/:id" />
      </Router>
    </Layout>
  )
}
